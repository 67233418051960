import {createSlice} from '@reduxjs/toolkit';
import jwtDecode from "jwt-decode";
import {WhoamiDTO} from "../auth/model/WhoamiDTO";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import {getAuthentications, initializeAuthentications} from "../localStorage/localStorageAuthenticationApi";
import {UserInfo} from "../auth/model/UserInfo";

type TokenState = {
    key:string,
    isLogin:boolean,
    currentUser: UserInfo
    expiration: number|null,
    lastTimeUserChecked:number|null
}

type NotLoggedTokenState = {
    isLogin: boolean,
    currentUser:undefined
    lastTimeUserChecked:number|null
}

const lastTimeUserChecked_LOCAL_STORAGE = "lastTimeUserChecked"
export const initialCurrentUser = undefined

export const isGrantedAccess = (localStorageKey:string) => {
    return localStorage.getItem(localStorageKey) !== null
}

export const getExistingRawToken = (key:string) => localStorage.getItem(key);
export const getInitialCurrentUser = (key:string): UserInfo|null => {
    const userInfo = localStorage.getItem("user-info")
    if(userInfo !== null){
        return JSON.parse(userInfo) as UserInfo
    }
    return null
}

export const getInitialTokenState = (key:string):TokenState|NotLoggedTokenState =>{
    const existingRawToken = getExistingRawToken(key)
    const existingDecodedToken:WhoamiDTO|undefined = (existingRawToken) ? jwtDecode(existingRawToken) : undefined
    const existingCurrentUser:UserInfo|undefined = (getInitialCurrentUser(key)!== null)? getInitialCurrentUser(key) as UserInfo: initialCurrentUser
    const lastTimeUserChecked = localStorage.getItem("lastTimeUserChecked")
    return {
        key:key,
        isLogin: existingDecodedToken ? existingDecodedToken.exp*1000 > Date.now() : false ,
        currentUser: existingCurrentUser ? existingCurrentUser: initialCurrentUser ,
        expiration: existingDecodedToken ? existingDecodedToken.exp*1000 :null,
        lastTimeUserChecked:lastTimeUserChecked !== null ? parseInt(lastTimeUserChecked):null
    }
}

initializeAuthentications()

const initialState = Array.from(getAuthentications()).map(auth => getInitialTokenState(auth))

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser(state, action:{type:string, payload:{currentUser: any, key:string}}) {
            const index = getTokenByKey(action.payload.key,state)
            if(index !== -1){
                state[index].currentUser = action.payload.currentUser
                state[index].isLogin = true
            }else{
                state.push({currentUser:action.payload.currentUser,key:action.payload.key, isLogin:true, lastTimeUserChecked:null, expiration:action.payload.currentUser.exp*1000 })
            }
        },
        setLastTimeUserChecked(state, action:{type:string, payload:{key:string, lastTimeUserChecked:string}}) {
            const index = getTokenByKey(action.payload.key,state)
            if(index !== -1){
                state[index].lastTimeUserChecked = parseInt(action.payload.lastTimeUserChecked)
                console.log("dispatching last time user checked")
                localStorage.setItem(`${lastTimeUserChecked_LOCAL_STORAGE}_${action.payload.key}`, action.payload.lastTimeUserChecked)
            }
        },
        setLogin(state,action:{type:string, payload:{key:string}}){
            const index = getTokenByKey(action.payload.key,state)
            if(index !== -1){
                state[index].isLogin = true
            }
        }
    },
});

const getTokenByKey= (key:string, state:any[])=>{
    return state.findIndex(element => element.key === key);
}

export const useGetToken: (key?:string) => TokenState | NotLoggedTokenState = (key?:string) => {
    const auth = useSelector((state:any) => state.auth) as TokenState[];
    return useMemo(() => {
        const currentAuth: TokenState | undefined = auth.find((elem: TokenState) => {
            return elem.key === key
        })
        return currentAuth ?? ({isLogin: false, currentUser:undefined, lastTimeUserChecked: null})
    }, [auth, key]);
}

export const { setCurrentUser,setLastTimeUserChecked, setLogin } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
