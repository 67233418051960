export const AUTH_ENTRYPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
export const check_login = AUTH_ENTRYPOINT + "/api/login_check"
export const registrationRoute = AUTH_ENTRYPOINT + "/register"
export const refreshToken = AUTH_ENTRYPOINT + "/refreshastotoken"

export const loginCondivisioneRoute = AUTH_ENTRYPOINT+`/api/condivisione/login_check`
export const getDocumentiArchiviatiRoute = AUTH_ENTRYPOINT + "/api/documenti/archiviati"
export const getDocumentiClienteRoute = (utenteId)=> AUTH_ENTRYPOINT + `/documenti/${utenteId}/cliente`
export const getVersioneDocumentoRoute = (documentoId, versioneId) => AUTH_ENTRYPOINT + `/get-documento/${documentoId}/versione/${versioneId}`
export const produciPdfRoute = AUTH_ENTRYPOINT + "/produci-pdf"
export const creaSessioneStripeRoute =(studioUuid)=>  AUTH_ENTRYPOINT + `/api/stripe/${studioUuid}/crea_sessione`
export const upgradePianoRoute =(studioUuid)=>  AUTH_ENTRYPOINT + `/api/studi/${studioUuid}/cambia_piano`
export const creaSeatsStripeRoute =(studioUuid)=>  AUTH_ENTRYPOINT + `/api/stripe/${studioUuid}/seats`
export const getConsulentiStudioRoute = (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/consulenti`
export const getClientiStudioRoute = (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/clienti`
export const uploadStudioLogoRoute = (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/upload_logo`
export const studioStatisticheRoute= (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/statistiche`
export const utenteStatisticheRoute= (studioUuid,consulenteUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/statistiche/consulente/${consulenteUuid}`

export const saveDocumentoRoute = (studioUuid, documentoId) => AUTH_ENTRYPOINT + `/documenti/${studioUuid}/${documentoId}`
export const rimuoviDocumentoRoute = (documentoId) =>AUTH_ENTRYPOINT + `/documento/${documentoId}`
export const avviaFirmaDigitaleRoute = (studioId,documentoId) =>AUTH_ENTRYPOINT + `/avvia-firma-digitale/${studioId}/${documentoId}`

export const getStudiosRoute = AUTH_ENTRYPOINT + `/api/admin/studi`
export const getStudiUtenteRoute = (utenteUuid) => AUTH_ENTRYPOINT + `/utenti/${utenteUuid}/studi`
export const getStudioDocumentiRoute =(studioUuid)=> AUTH_ENTRYPOINT + `/api/admin/studio/${studioUuid}/documenti`
export const queryNormeRoute = AUTH_ENTRYPOINT + "/api/norme"
export const queryArticoliCostituzioneRoute = AUTH_ENTRYPOINT + "/api/articoli_costituzione"
export const creaStudioRoute = AUTH_ENTRYPOINT + `/api/admin/studio`

export const acquista10FirmeRoute= (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/acquisto_10_firme`
export const acquista20FirmeRoute= (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/acquisto_20_firme`
export const acquista50FirmeRoute= (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/acquisto_50_firme`
export const acquistaFEAFirmeRoute= (studioUuid) => AUTH_ENTRYPOINT + `/studio/${studioUuid}/fea`

export const inviaEmailKopjraRoute = (kopjraId) => AUTH_ENTRYPOINT+ `/firme/${kopjraId}/invia_email`

//TEMPLATE
export const salvaTemplateRoute = (utenteId) => AUTH_ENTRYPOINT+ `/templates/${utenteId}`
export const rimuoviTemplateRoute = (templateId) =>AUTH_ENTRYPOINT + `/templates/${templateId}`

export const creaClausolaRoute = AUTH_ENTRYPOINT + `/clausole`
export const getClausoleRoute = AUTH_ENTRYPOINT + `/clausole`
export const rimuoviClausolaRoute = (id)=> AUTH_ENTRYPOINT + `/clausole/${id}`

export const connectionRoute = AUTH_ENTRYPOINT+ `/connection`;
export const getStaffRoute = AUTH_ENTRYPOINT + `/staff`
export const cediDocumentoStaff = (documentoUuid) => AUTH_ENTRYPOINT + `/documenti/${documentoUuid}/staff`;

export const estendiPasswordRoute = (utenteUuid) => AUTH_ENTRYPOINT + `/utenti/${utenteUuid}/estendi_forgot_time`;
