// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import {getAnalytics} from "firebase/analytics";

console.log(process.env.REACT_APP_FIREBASE_CONFIG)
//@ts-ignore
/*const firebaseConfig= JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)*/

const firebaseConfig ={"apiKey":"AIzaSyDIrsA4Hhu8TwKHagptnEkF9NCCNtS50tY","authDomain":"lexhero-dev.firebaseapp.com","projectId":"lexhero-dev","storageBucket":"lexhero-dev.appspot.com","messagingSenderId":"469211222310","appId":"1:469211222310:web:5929c7b4617ba53f05f1e7","measurementId":"G-RCNFE0YXDT"}
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
