import React from 'react'
import {refreshToken} from "../routing/api-routes";

type Auth = {
    auth: true,
    tokenStorageName: string,
}

type ShouldRefresh = {
    shouldRefresh: true,
    shouldRefreshRoute: string,
    getExtraParams?: () => object
}

type ShouldNotRefresh = {
    shouldRefresh: false,
    shouldRefreshRoute?: never,
    getExtraParams?: never
}

type NoAuth = {
    auth:false,
    tokenStorageName?: never
    shouldRefresh?: never
}

type AuthorizationRefresh = ShouldRefresh | ShouldNotRefresh

export type AuthorizationAuthValue = (Auth & AuthorizationRefresh) | NoAuth

export const authorizationContextValue:AuthorizationAuthValue = {
    tokenStorageName: "token",
    shouldRefresh: true,
    shouldRefreshRoute: refreshToken,
    auth:true,
    getExtraParams: () => {
        return {}
    }

}
const AuthorizationContext = React.createContext<AuthorizationAuthValue>(authorizationContextValue)
export default AuthorizationContext
