import {parse, stringify} from "flatted";
const authenticationsKey = "authentications";
export const userInfoLocalStorageKey = "user-info";
export const addAuthentication = (authenticationKey:string, authenticationValue:string) =>{
    const authentications= getAuthentications()
    authentications.add(authenticationKey)
    localStorage.setItem(authenticationKey, authenticationValue)
    setAuthentications(authentications);
}

export const initializeAuthentications = () =>{
    const authentications = localStorage.getItem(authenticationsKey)
    const userInfo = localStorage.getItem(userInfoLocalStorageKey)
    if(userInfo === null && authentications!==null){
        localStorage.removeItem("token")
        return
    }

    if(authentications!== null) return

    const authenticationToken = localStorage.getItem("token")


    localStorage.setItem(authenticationsKey,authenticationToken ? stringify(["token"]): stringify([]));
}


export const getAuthentications = ():Set<string> =>{
    const authenticationKey = localStorage.getItem(authenticationsKey)
    if(authenticationKey === null) throw new Error("Authentication local storage key missing")
    return new Set(parse(authenticationKey))
}

export const setAuthentications = (authentications:Set<string>) =>{
    localStorage.setItem(authenticationsKey, stringify(Array.from(authentications)))
}
