import {FornitoriForm} from "../../views/admin/Fornitori/FornitoriFormType";


export default class FornitoreObject {
    nome: string;
    url?: string;
    logoPath?: string;
    permessi?: string[];
    s3Bucket?: string;
    s3BucketUrl?: string;
    cognitoUserPoolId?: string;
    isDefault?: boolean = false;
    faviconPath?: string;
    testoEmailRegistrazione?: string;

    constructor(data: FornitoriForm) {

        this.nome = data.nome || '';
        this.url = data.url;
        this.s3Bucket = data.s3Bucket;
        this.s3BucketUrl = data.s3BucketUrl;
        this.cognitoUserPoolId = data.cognitoUserPoolId;
        this.isDefault = data.isDefault;
        this.testoEmailRegistrazione = data.testoEmailRegistrazione;

        this.permessi = [];
        if (data.iscrizione) this.permessi.push('iscrizione');
        if (data.billing) this.permessi.push('billing');
        if (data.marketPlace) this.permessi.push('marketPlace');


        this.logoPath = data.logoPath;
        this.faviconPath = data.faviconPath;
    }





}
