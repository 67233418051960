export const AGGIUNGI_TEMPLATE = 'Aggiunto template'
export const AGGIUNGI_DOCUMENTO_VUOTO = "Aggiunto documento vuoto"

export const AGGIUNGI_ALLEGATO = "Aggiunto allegato"

export const AGGIUNGI_DOCX = "Importato DOCX"

export const CREA_MULTI_DOC = "Creazione multi doc"

export const TEMPLATE_SEARCH_BAR = "Ricerca template"

export const GENERATE_AI = "Generazione AI"

export const ADD_AI = "AI aggiunta documento"

export const SAVE_AI = "AI salvata clausole"

export const COPY_AI = "AI copiata"

export const DOWNLOAD_PDF = "Download PDF"
export const DOWNLOAD_DOCX = "Download DOCX"


export const DOPPIA_FIRMA = "Doppia firma"
export const USO_DELL_ALERT =" Alert"
export const ADD_COMMENT = "Aggiunto commento"

export const ADD_COVER =" Aggiunta copertina"

export const SHOW_LOGO ="Mostra logo"

export const USE_STUDIO_LOGO = "Usa Logo studio"

export const HEADER_LOGO = "Header logo"

export const FOOTER_LOGO = "Footer logo"

export const HEADER_SINISTRA = "Header sinistra"

export const HEADER_DESTRA = "Header destra"

export const FOOTER_SINISTRA = "Footer sinistra"

export const FOOTER_DESTRA = "Footer destra"

export const TESTO_ANGOLO =  "Testo angolo"

export const CAMBIA_FORMATO_PAGINA = "Cambia formato pagina"

export const COPERTINA_TITOLO_DOCUMENTO = "Cover Titolo Documento"

export const COPERTINA_SOTTOTITOLO_DOCUMENTO = "Cover Sottotitolo Documento"

export const COPERTINA_FONTSIZE_TITOLO = "Cover Fontsize Titolo"

export const COPERTINA_FONTSIZE_SOTTOTITOLO = "Cover Fontsize Sottotitolo"

export const COPERTINA_CHANGE_COLOR_COVER = "Cover colore"

export const COPERTINA_CHANGE_COLOR_TITLE = "Cover colore titolo"

export const COPERTINA_CHANGE_COLOR_SUBTITLE = "Cover colore sottotiolo"

export const STILEDOCUMENTO_MARGIN_X ="Margine X documento"

export const STILEDOCUMENTO_FONTSIZE ="Fontsize documento"

export const STILEDOCUMENTO_FAMILY = "Fontfamily documento"

export const STILEDOCUMENTO_MARGIN_Y = "Margine Y documento"

export const STILEDOCUMENTO_LINE_HEIGHT ="Lineheight documento"

export const FORMATTAZIONE_DOCUMENTO_STILE_DEFINIZIONE = "Stile definizione"

export const FORMATTAZIONE_DOCUMENTO_STILE_RIFERIMENTO =" Stile riferimento"

export const SHOW_TITOLO_FORMATTAZIONE ="Show titolo formattazione"

export const FORMATTAZIONE_DOCUMENTO_STILE = "formattazione_documento_stile"

export const FORMATTAZIONE_DIMENSIONE_ARTICOLO = "Dimensione articolo"

export const FORMATTAZIONE_POSIZIONE_ARTICOLO = "Posizione articolo"

export const LOG_REFRESH_TOKEN = "Refresh token"
export const LOG_REGISTRAZIONE_STUDIO_FREE = "Registrazione studio free"

export const LOG_ERROR = "Errore log"

export const LOG_RICERCA_NORMATIVA = "Ricerca Normativa"
export const LOG_RICERCA_GIURISPRUDENZA = "Ricerca giurisprudenza"
