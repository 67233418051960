import {useCallback} from "react";
import {addAuthentication} from "../localStorage/localStorageAuthenticationApi";
import {WhoamiDTO} from "./model/WhoamiDTO";
import {useGet, usePost} from "../httpClient/useAuthClient";
import {useIntl} from "react-intl";
import {toastOnError} from "../utils/toastUtils";
import client from "../httpClient/client";
import {AUTH_ENTRYPOINT, refreshToken} from "../routing/api-routes";
import {useDispatch} from "react-redux";
import {setLogin} from "../redux/authSlice";

interface LoginPayload{
    username:string,
    password:string,
    loginRoute: string
}

interface LoginResponse {
    refresh_token:string
    token: string
}

const whoamiRoute = AUTH_ENTRYPOINT + "/whoami"
export function useLogin(key:string, shouldRefresh:boolean = false){
    const {post} = usePost<LoginResponse>({auth:false});
    const {formatMessage:f} = useIntl()
    return useCallback(async ({username, password, loginRoute}: LoginPayload) => {
        try {
            const response = await post(loginRoute, JSON.stringify({username, password}));
            addAuthentication(key, JSON.stringify(response.data.token));
            if(shouldRefresh) localStorage.setItem(`refresh${key}`, JSON.stringify(response.data["refresh_token"]));
            return response;
        } catch (reason) {
            throw reason
        }
    },[f,key, post,shouldRefresh])
}

export function useRefresh(key:string){
    const dispatch = useDispatch()
    //"userKey": localStorage.getItem(`user-key`),
    return useCallback((extraPayloadData:any={}) =>{
        return client.post(refreshToken,JSON.stringify({...extraPayloadData,"refresh_token": localStorage.getItem(`refresh${key}`)?.slice(1,-1)}))
            .then(response => {
            addAuthentication(key, JSON.stringify(response.data.token));
            localStorage.setItem(`refresh${key}`, JSON.stringify(response.data["refresh_token"]));
            dispatch(setLogin({key:key}))
            return response
        }).catch(reason => {
            throw reason
        })
    },[localStorage])
}

export const useWhoami = () =>{
    const {get} = useGet<WhoamiDTO>();
    const {formatMessage:f} = useIntl()

    return useCallback(async () => {
        try {
            return await get(whoamiRoute)
        } catch (reason) {
            toastOnError(f({id: "system.invalidCredentials"}));
            throw reason;
        }
    },[f,get])
}
